import React, { useState } from 'react';
import ModalComponent from '../../Modal';
import { ReactComponent as TennisRacket } from "../../../images/programs/tennis-racket.svg";
import { ReactComponent as CloseIcon } from "../../../images/close.svg";
import '../../../styles/programs/adultPopup/index.css';

const AdultPopup = ({ modalIsOpen, openModal, closeModal }) => {
  
  const programData = [
    {
      mainTitle: "Women group session",
      subtitle: {
        label: "Session:",
        value: "1 hr by week/program of 10 weeks",
      },
      programs: [
        {
          title: "Women program level 1 beginner 4 players by court",
          details: [
            {
              label: "Age:",
              value: "From 15 years old.",
            },
            {
              label: "Balls:",
              value: "Green and yellow.",
            },
            {
              label: "Rocket size:",
              value: "Adults.",
            },
          ],
          price: {
            label: "10 group sessions with 1 match session: ",
            value: "1200SAR",
          },
        },
        {
          title: "Women program level 2 intermediary 4 players by court",
          details: [
            {
              label: "Age:",
              value: "From 15 years old.",
            },
            {
              label: "Balls:",
              value: "Green and yellow.",
            },
            {
              label: "Rocket size:",
              value: "Adults.",
            },
          ],
          price: {
            label: "10 group sessions with 1 match session: ",
            value: "1200SAR",
          },
        },
        {
          title: "Women program level 3 advanced 4 players by court",
          details: [
            {
              label: "Age:",
              value: "From 15 years old.",
            },
            {
              label: "Balls:",
              value: "Green and yellow.",
            },
            {
              label: "Rocket size:",
              value: "Adults.",
            },
          ],
          price: {
            label: "10 group sessions with 1 match session: ",
            value: "1200SAR",
          },
        },
      ],
    },
    {
      mainTitle: "Women private or semi-private lesson",
      subtitle: {
        label: "Session:",
        value: "1 player per court/session of 1 hour",
      },
      title: "Customized program",
      details: [
        {
          label: "Age:",
          value: "From 11 years old.",
        },
        {
          label: "Balls:",
          value: "Depend of age and level.",
        },
        {
          label: "Rocket size:",
          value: "21 in. to adults.",
        },
      ],
      price: {
        label: "Price: ",
        value: "250SAR/hr (1 player), 380SAR/hr (2 players)",
      },
    },
    // {
    //   mainTitle: "Men group sessions",
    //   subtitle: {
    //     label: "Session:",
    //     value: "Session of 1 hour by week/program of 10 weeks/4 players by court",
    //   },
    //   programs: [
    //     {
    //       title: "Customized program",
    //       details: [
    //         {
    //           label: "Age:",
    //           value: "From 17 years old.",
    //         },
    //         {
    //           label: "Balls:",
    //           value: "Green and yellow.",
    //         },
    //         {
    //           label: "Rocket size:",
    //           value: "Adults.",
    //         },
    //       ],
    //       price: {
    //         label: "10 group sessions with 1 match session: ",
    //         value: "1200SAR",
    //       },
    //     },
    //   ],
    // },
    {
      mainTitle: "Men private or semi-private lesson",
      subtitle: {
        label: "Session:",
        value: "1 player per court/session of 1 hour",
      },
      title: "Customized program",
      details: [
        {
          label: "Age:",
          value: "From 11 years old.",
        },
        {
          label: "Balls:",
          value: "Depend of age and level.",
        },
        {
          label: "Rocket size:",
          value: "21 in. to adults.",
        },
      ],
      price: {
        label: "Price:",
        value: "250SAR/hr (1 player), 380SAR/hr (2 players)",
      },
    },
  ];
  
  const getProgramType = (title) => {
    const lowerTitle = title.toLowerCase();
    if (lowerTitle.includes('men') && !lowerTitle.includes('women')) {
      return 'men';
    } else if (lowerTitle.includes('women')) {
      return 'women';
    } else {
      return 'default'; // In case neither 'men' nor 'women' is specified
    }
  };

  return (
    <div>
      <ModalComponent isOpen={modalIsOpen} closeModal={closeModal}>
          <div className="section-header-adultPopup-container">
            <div className="title">Adult Program</div>
            <div className="icon" onClick={closeModal}>
              <CloseIcon />
            </div>
          </div>
        <section className="adultPopup-container">
          <div className='scrollable-box'>
            {programData.map((program, index) => {
              const programType = getProgramType(program.mainTitle);
              return (
                <div key={index} className="program-details">
                  <div className="program-item p-title">
                    <div className="title">{program.mainTitle}</div>
                    {program.subtitle && (
                      <div className="details">
                        <div className="info">
                          <div>
                            <span className="label">{program.subtitle.label}</span>
                            <span className="value">{program.subtitle.value}</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {program.programs ? (
                    program.programs.map((subProgram, subIndex) => (
                      <div key={subIndex} className="program-item">
                        <div className="title">{subProgram.title}</div>
                        <div className="details">
                          <div className={`icon ${programType}`}>
                            <TennisRacket />
                          </div>
                          <div className="info">
                            {subProgram.details.map((detail, idx) => (
                              <div key={idx}>
                                <span className="label">{detail.label}</span>
                                <span className="value">{detail.value}</span>
                              </div>
                            ))}
                          </div>
                        </div>
                        {subProgram.price && (
                          <div className="price">
                            <span className="label">{subProgram.price.label}</span>
                            <span className="intro">Start from</span>
                            <span className="value">{subProgram.price.value}</span>
                          </div>
                        )}
                      </div>
                    ))
                  ) : (
                    <div className="program-item">
                      <div className="title">{program.title}</div>
                      <div className="details">
                        <div className={`icon ${programType}`}>
                          <TennisRacket />
                        </div>
                        <div className="info">
                          {program.details.map((detail, idx) => (
                            <div key={idx}>
                              <span className="label">{detail.label}</span>
                              <span className="value">{detail.value}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                      {program.price && (
                        <div className="price">
                          <span className="label">{program.price.label}</span>
                          <span className="intro">Start from</span>
                          <span className="value">{program.price.value}</span>
                        </div>
                      )}
                    </div>
                  )}
                  {index !== programData.length - 1 && <div className="divider"></div>}
                </div>
              );
            })}
            <div style={{ height: "30px" }}></div>
          </div>
        </section>
      </ModalComponent>
    </div>
  );
};

export default AdultPopup;