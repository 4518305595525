import React from "react";
import "../../styles/footer/index.css";
import thePalmsLogo from "../../images/footer/ThePalmsLogo-md.png";
import xLogo from "../../images/footer/x_logo.png";
import instagramLogo from "../../images/footer/instagram_logo.png";
import tiktokLogo from "../../images/footer/tiktok_logo.png";
import snapshatLogo from "../../images/footer/snapshat_logo.png";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <section className="footer-container">
		<div className="common-container">
			<div className="footer-content">
				<div className="footer-top">
				<Link
					to='/'
					onClick={() => {
					window.scrollTo({
					left: 0,
					top: 0,
					behavior: 'smooth',
					});
					}}
				>
					<img className="footer-logo" src={thePalmsLogo} alt="The Palms Logo" />
				</Link>
				<div className="footer-socials">
					<img className="footer-social-icon" src={xLogo} alt="X Logo" onClick={() => window.open('https://x.com/thepalmsracket?t=usHq3QOOTXHzkPp6XWYWqw&s=09', '_blank')}  />
					<img className="footer-social-icon" src={instagramLogo} alt="Instagram Logo" onClick={() => window.open('https://www.instagram.com/thepalmsracket?utm_source=qr&igsh=YWk3ZnRubHFkODZj', '_blank')} />
					<img className="footer-social-icon" src={tiktokLogo} alt="TikTok Logo" onClick={() => window.open('https://vm.tiktok.com/ZSYrJr1WH', '_blank')}  />
					<img className="footer-social-icon" src={snapshatLogo} alt="Snapchat Logo" onClick={() => window.open('https://snapchat.com/t/35tsXKIp', '_blank')} />
				</div>
				</div>
				<div className="footer-bottom">
				<div className="footer-divider" />
				<div className="footer-bottom-content">
					<div className="footer-copy">Copyright © 2024 The Palms | Powered by Connect to fit</div>
					<div className="footer-links">
					<Link
						to='/AboutUs'
						className="footer-link"
						onClick={() => {
							window.scrollTo({
							left: 0,
							top: 0,
							behavior: 'smooth',
							});
						}}
					>
						About Palms
					</Link>
					<Link
						to='/RulesAndRegulations'
						className="footer-link"
						onClick={() => {
							window.scrollTo({
							left: 0,
							top: 0,
							behavior: 'smooth',
							});
						}}
					>
						Rules & Regulations
					</Link>
					</div>
				</div>
				</div>
			</div>
		</div>
    </section>
  );
}

export default Footer;
