import { React, useState } from "react";
import Hero from "../../components/Hero";
import About from "../../components/About";
import Programs from "../../components/Programs";
import Coaches from "../../components/Coaches";
import FindClub from "../../components/FindClub";
import Partners from "../../components/Partners";
import ContactUs from "../../components/ContactUs";
import Services from "../../components/Services";

function Home({ }) {
    return (
        <>
            <Hero/>
            <About/>
            <Programs/>
            <Coaches/>
            <FindClub/>
            <Services/>
            {/* hide it for now */}
            {/* <Partners/>  */}
            <ContactUs/>
        </>
    );
}

export default Home;
