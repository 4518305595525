import React from "react";
import "../../styles/rulesAndRegulations/index.css";

function RulesAndRegulations() {
  return (
    <section className="rulesAndRegulations-container">
      <div className="common-container">
        <div className="rulesAndRegulations-header">
          <h1>Rules & Regulations</h1>
        </div>
        <div className="rulesAndRegulations-content">
          <h2>General:</h2>
          <ul>
            <li>Please respect players on court by not making too much noise or causing distraction.</li>
            <li>Please protect your own possessions and respect those of others. The Palms Racquet Club & Academy accepts no responsibility for items lost, stolen, or damaged on its premises.</li>
            <li>Lost property, balls, or equipment found should be handed back to the owner or to reception.</li>
            <li>Damage caused while at The Palms Racquet Club & Academy is chargeable to the offender(s).</li>
            <li>To the extent permitted by law, The Palms Racquet Club & Academy will not accept responsibility for any injuries incurred during play at our premises.</li>
            <li>Personal items should not be left on the premises overnight.</li>
            <li>Appropriate sporting attire should be worn on court, and shirts must be worn at all times.</li>
            <li>Players are required to wear non-marking footwear when on court. Players cannot wear metal studs or any form of studs on any of the courts.</li>
            <li>Only The Palms Racquet Club & Academy team members may coach clients and/or charge for coaching services using our courts.</li>
            <li>The Palms Racquet Club & Academy reserves the right to refuse admission to any person whether or not a fee has previously been paid in respect of entry and/or the use of the facilities.</li>
          </ul>

          <h2>Court Booking:</h2>
          <ul>
            <li>Court bookings are available on the hour every hour based on availability.</li>
            <li>Back-to-back individual court bookings are permitted only during off-peak times.</li>
            <li>Court bookings must be paid for before you play.</li>
            <li>Court bookings can be made online, over the telephone, or in person and are to be confirmed via email by The Palms Racquet Club & Academy.</li>
            <li>Players should contact reception on arrival and prior to going on.</li>
            <li>Attendance later than 15 minutes after the hour may result in loss of booking and the court being allocated to other players.</li>
            <li>Court usage in poor weather conditions is at the individual’s choice and risk.</li>
            <li>Only The Palms Racquet Club & Academy coaches can train on the courts.</li>
            <li>Adult supervision is required at all times when juniors are playing.</li>
          </ul>

          <h2>Group Lessons, Programs & Camps:</h2>
          <ul>
            <li>Registration should be completed prior to the start of programs (exceptions can be made by the Coaches).</li>
            <li>Payment must be made upon registration to confirm your place.</li>
            <li>Places will be confirmed on a first-come, first-serve basis.</li>
            <li>Once the maximum number of attendees is reached, a waiting list will be created and attendees contacted if places free up.</li>
            <li>Programs that are undersubscribed may be cancelled, with The Palms Racquet Club & Academy offering alternative suggestions to interested players.</li>
            <li>A missed session may be caught up only via another suitable session at the discretion of The Palms Racquet Club & Academy.</li>
            <li>Missed sessions will not be credited unless there are exceptional circumstances.</li>
            <li>Camps will run for the scheduled time irrespective of the weather. Play will continue whenever safety is not compromised. If necessary, coaches may postpone the session and reschedule.</li>
            <li>Players must inform The Palms Racquet Club & Academy of any medical condition or disability which may be relevant to the Player. Players must also notify us of any change or deterioration of any such medical condition or disability. Information will be kept confidential and will be handled with discretion. It is The Palms Racquet Club & Academy policy to include any such individuals within the scheduled courses wherever possible, but we reserve the right to refuse the booking if we are regrettably unable to accommodate the needs of the individual.</li>
            <li>We are unable to accept replacements in lieu of your booking.</li>
            <li>In exceptional circumstances and where camp/course capacity allows, the Senior Coach and staff may authorize for an attendee to transfer the reservation to/from another time period.</li>
          </ul>

          <h2>Junior Players:</h2>
          <ul>
            <li>All children must be fully supervised by an adult. While The Palms Racquet Club & Academy will do its utmost to look after them while they are on the court, we cannot undertake to supervise children before, after, or around our facilities.</li>
            <li>All children booked onto junior courses and camps must bring appropriate weather protection on court at the beginning of the session. They are not permitted to leave the court without permission from the coach.</li>
            <li>Permission for children to go home alone must be given in writing. If written permission is not received and contact cannot be made with parent/guardian, we will keep the child until they are picked up or contact is made.</li>
          </ul>

          <h2>Individual Coaching:</h2>
          <ul>
            <li>Individual coaching sessions cancelled less than 24 hours before are liable to pay the coach in full.</li>
            <li>Communication regarding Individual coaching sessions are arranged directly with the coach concerned.</li>
            <li>Management of coach bookings is controlled by the coach in question.</li>
            <li>Payments cannot be made directly to the coach.</li>
            <li>Refer also to the Cancellation Policy.</li>
          </ul>

          <h2>Cancellation Policy:</h2>
          <ul>
            <li>Any necessary court cancellations should be made with more than 24 hours notice prior to the court booking time. Cancellations made within 24 hours will be charged the full fee.</li>
            <li>Cancellation before this time can be done through your mobile app.</li>
            <li>Cancellations made after this time must call the club and exceptional circumstances will be taken into consideration.</li>
            <li>In adverse weather conditions the player should contact The Palms Racquet Club & Academy to check if play is possible. The Palms Racquet Club & Academy will make the final decision. If the court is deemed unplayable, the player can request a credit to be applied to their account.</li>
            <li>Play in adverse conditions is at the player’s own choice & risk.</li>
            <li>No cash refunds will be made, all refunds will be made via bank transfer and can take up to 30 days.</li>
          </ul>

          <h2>Programs & Cancellations:</h2>
          <ul>
            <li>Any decision to cancel a session due to rain or sandstorm is based on the extent to which courts are affected, and in the case of young players, if rain or sandstorm is likely to be a significant distraction or result in a negative experience.</li>
            <li>If the weather forecast prior to the session is poor, any decision to cancel will be made by the Senior Coach as late as is reasonably possible. Whenever possible, customers will be sent an email/text once a decision to cancel has been made. As a last resort customers should call the coach/office for an update. Please bear in mind difficulties in trying to predict the weather. Our overall intention is for as many sessions as possible to go ahead.</li>
            <li>If a session runs over half of the designated time and is interrupted due to weather conditions, the session is considered a full session and no credit will be issued.</li>
            <li>If a session is interrupted due to bad weather conditions before half of the session is completed, the player can request a credit note to be applied to their account.</li>
            <li>The Palms Racquet Club & Academy reserves the right to change the location of the lesson and also change the type of lesson while maintaining the equivalent financial value for the client.</li>
            <li>In the unlikely event of insufficient numbers, we reserve the right to cancel courses 3 days in advance of the start date. A credit will be applied to customer’s account.</li>
            <li>If the participant is absent from a session for a reason other than The Palms Racquet Club & Academy cancelling the session due to adverse weather, he/she is choosing to forfeit the time. Only in exceptional circumstances will discretion be used to calculate the pro rata rate of the session and a credit note issued.</li>
            <li>Cash refunds are limited to exceptional circumstances.</li>
          </ul>

          <h2>Payments:</h2>
          <ul>
            <li>All credit/debit cards details and personally identifiable information will NOT be stored, sold, shared, rented, or leased to any third parties.</li>
            <li>The Website Policies and Terms & Conditions may be changed or updated occasionally to meet the requirements and standards. Therefore, the Customers are encouraged to frequently visit these sections in order to be updated about the changes on the website. Modifications will be effective on the day they are posted.</li>
            <li>Refunds can only be done only through the Original Mode of Payment.</li>
            <li>Any purchase, dispute, or claim arising out of or in connection with this website shall be governed and construed in accordance with the laws of the Kingdom of Saudi Arabia.</li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default RulesAndRegulations;
