import React from 'react';
import ModalComponent from '../../Modal';
import { ReactComponent as TennisRacket } from "../../../images/programs/tennis-racket.svg";
import { ReactComponent as CloseIcon } from "../../../images/close.svg";
import '../../../styles/programs/juniorPopup/index.css';

const JuniorPopup = ({ modalIsOpen, closeModal }) => {
  const programData = [
    {
      mainTitle: "Junior tennis school (September - June)",
      subtitle: {
        label: "",
        value: "6 players per group/session of 1 hr/mixed group"
      },
      programs: [
        {
          title: "Purple program",
          subtitle: "(except during school vacations and Ramadan)",
          info: "6 players by court - 1 session by week recommended",
          details: [
            {
              label: "Age:",
              value: "From 6 years old."
            },
            {
              label: "Balls:",
              value: "Purple"
            },
            {
              label: "Rocket size:",
              value: "19 inches"
            },
          ],
          price: {
            label: "10 group sessions with 1 evaluation: ",
            value: "850 SAR"
          },
          backgroundColor: "#A020F0"
        },
        {
          title: "Red program",
          subtitle: "(except during school vacations and Ramadan)",
          info: "6 players by court - 1 session by week recommended",
          details: [
            {
              label: "Age:",
              value: "From 7 years old."
            },
            {
              label: "Balls:",
              value: "Red"
            },
            {
              label: "Rocket size:",
              value: "10-21 inches"
            },
          ],
          price: {
            label: "10 group sessions with 1 evaluation: ",
            value: "850 SAR"
          },
          backgroundColor: "#FA0B0B"
        },
        {
          title: "Orange program",
          subtitle: "(except during school vacations and Ramadan)",
          info: "6 players by court - 2 sessions by week recommended",
          details: [
            {
              label: "Age:",
              value: "From 8 years old."
            },
            {
              label: "Balls:",
              value: "Orange"
            },
            {
              label: "Rocket size:",
              value: "21-23 inches"
            },
          ],
          price: {
            label: "10 group sessions with 1 evaluation: ",
            value: "850 SAR"
          },
          backgroundColor: "#F4890C"
        },
        {
          title: "Green program",
          subtitle: "(except during school vacations and Ramadan)",
          info: "6 players by court - 2 sessions by week recommended",
          details: [
            {
              label: "Age:",
              value: "From 9-10 years old."
            },
            {
              label: "Balls:",
              value: "Green"
            },
            {
              label: "Rocket size:",
              value: "23-25 inches"
            },
          ],
          price: {
            label: "10 group sessions with 1 evaluation: ",
            value: "850 SAR"
          },
          backgroundColor: "#57AD2F"
        },
        {
          title: "Teen’ program",
          subtitle: "(except during school vacations and Ramadan)",
          info: "4 players by court - 2 sessions by week recommended",
          details: [
            {
              label: "Age:",
              value: "From 11-18 years old."
            },
            {
              label: "Balls:",
              value: "Green and yellow"
            },
            {
              label: "Rocket size:",
              value: "23-25 inches"
            },
          ],
          price: {
            label: "10 group sessions with 1 match session: ",
            value: "1200 SAR"
          },
          backgroundColor: "#5380F3",
        }
      ]
    },
    {
      mainTitle: "Private or semi-private lessons",
      subtitle: {
        label: "",
        value: "1 player per court/session of 1 hour"
      },
      programs: [
        {
          title: "Customized program",
          subtitle: "",
          info: "",
          details: [
            {
              label: "Age:",
              value: "From 8-18 years old."
            },
            {
              label: "Balls:",
              value: "Depend of age and level."
            },
            {
              label: "Rocket size:",
              value: "21 in. to adults."
            },
          ],
          price: {
            label: "Price: Start from ",
            value: "250SAR/hr (1 player), 380SAR/hr (2 players)"
          },
          backgroundColor: "#FFFFFF",
          className: "private-lesson",
        }
      ]
    }
  ];
  

  return (
    <div>
      <ModalComponent isOpen={modalIsOpen} closeModal={closeModal}>
        <div className="section-header-juniorPopup-container">
          <div className="title">Junior Program</div>
          <div className="icon" onClick={closeModal}>
            <CloseIcon />
          </div>
        </div>
        <section className="juniorPopup-container">
          <div className='scrollable-box'>
            {programData.map((program, index) => {
              return (
                <div key={index} className="program-details">
                  <div className="program-item p-title">
                    <div className="title">{program.mainTitle}</div>
                    {program.subtitle && (
                      <div className="details">
                        <div className="info">
                          <div>
                            <span className="label">{program.subtitle.label}</span>
                            <span className="value">{program.subtitle.value}</span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {program.programs ? (
                    program.programs.map((subProgram, subIndex) => (
                      <div key={subIndex} className="program-item">
                        <div className="title">
                          {subProgram.title}
                          <span style={{fontWeight: 400, marginLeft: "5px", color: "#34495E"}}>{subProgram.subtitle}</span>
                          <p style={{fontWeight: 400 }}>{subProgram.info}</p>
                        </div>
                        <div className="details">
                          <div className={`icon ${subProgram.className ? subProgram.className : ""}`} style={{backgroundColor: `${subProgram.backgroundColor}`}}>
                            <TennisRacket />
                          </div>
                          <div className="info">
                            {subProgram.details.map((detail, idx) => (
                              <div key={idx}>
                                <span className="label">{detail.label}</span>
                                <span className="value">{detail.value}</span>
                              </div>
                            ))}
                          </div>
                        </div>
                        {subProgram.price && (
                          <div className="price">
                            <span className="label">{subProgram.price.label}</span>
                            <span className="value">{subProgram.price.value}</span>
                          </div>
                        )}
                      </div>
                    ))
                  ) : (
                    <div className="program-item">
                      <div className="title">{program.title}</div>
                      <div className="details">
                        <div className={`icon`}>
                          <TennisRacket />
                        </div>
                        <div className="info">
                          {program.details.map((detail, idx) => (
                            <div key={idx}>
                              <span className="label">{detail.label}</span>
                              <span className="value">{detail.value}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                      {program.price && (
                        <div className="price">
                          <span className="label">{program.price.label}</span>
                          <span className="intro">Start from</span>
                          <span className="value">{program.price.value}</span>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="divider"></div>
                </div>
              );
            })}
            {/* Hardcoded section */}
            <div className="program-details">
              <div className="program-item p-title">
                <div className="title">Junior camps (during schools holidays and Ramadan)</div>
                  <div className="details">
                    <div className="info">
                      <div>
                        <span className="value">6 player per court/1 week (5 days)/2 hrs bu day/introductory price</span>
                      </div>
                    </div>
                  </div>
              </div>
              <div className="program-item">
                <div className="details">
                  <div className="info">
                      <div>
                        <span className="label">Age</span>
                        <span className="value">7/8 years</span>
                      </div>
                      <div>
                        <span className="label">Age</span>
                        <span className="value">9/10 years</span>
                      </div>
                      <div>
                        <span className="label">Age</span>
                        <span className="value">11/14 years</span>
                      </div>
                  </div>
                  <div className="info" style={{marginLeft: "10px"}}>
                      <div>
                        <span className="label">Sun-Thu:</span>
                        <span className="value">850SAR</span>
                      </div>
                      <div>
                        <span className="label">Sun-Thu:</span>
                        <span className="value">850SAR</span>
                      </div>
                      <div>
                        <span className="label">Sun-Thu:</span>
                        <span className="value">850SAR</span>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ends here */}
            <div style={{ height: "30px" }}></div>
          </div>
        </section>
      </ModalComponent>
    </div>
  );
};

export default JuniorPopup;
