import { React, useState, Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import RulesAndRegulations from './pages/RulesAndRegulations';
import Header from './components/Header';
import Footer from './components/Footer';
import { ReactComponent as LeftArrowIcon } from "./images/left-arrow.svg";
import WelcomePopup from './components/WelcomePopup';

function App() {
  const [y, setY] = useState(window.scrollY);
  const [isLoading, setIsLoading] = useState(window.location.pathname == "/" ? true : false);
  const [welcomeModalIsOpen, setWelcomeModalIsOpen] = useState(false);

  window.onscroll = function () {
    setY(window.scrollY);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setWelcomeModalIsOpen(true);
    }, 5 *1000); //show welcome popup after 5 seconds.

    return () => clearTimeout(timer);
  }, []);


  return (
    <>
      {/* {
        isLoading &&
        <div id="loading">
          <LoadingState/>
        </div>
      } */}
      
      {/* turn welcome popup off for now */}
      {/* <WelcomePopup modalIsOpen={welcomeModalIsOpen} closeModal={()=> setWelcomeModalIsOpen(false)}/> */}
      <Router>
        <div id='app' className={isLoading ? "d-none" : ""}>
          <Suspense fallback={null}>
            {Number(y) >= 120 && (
              <button
                className='go-up'
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                  });
                }}
              >
                <LeftArrowIcon/>
              </button>
            )}
            <Header />

            <Routes>
              <Route exact path='/' element={<Home setIsLoading={setIsLoading} />} />
              <Route exact path='/AboutUs' element={<AboutUs />} />
              <Route exact path='/RulesAndRegulations' element={<RulesAndRegulations />} />
            </Routes>
            <Footer />
          </Suspense>
        </div>
      </Router>
    </>
  );
}

export default App;
