import { React } from "react";
import "../../styles/header/index.css";
// import thePalmsLogo from "../../images/header/ThePalmsLogo-sm.png";
import thePalmsLogo from "../../images/header/ThePalmsLogo-sm-white.png";
import { ReactComponent as SearchIcon } from "../../images/header/search-icon.svg";
import { ReactComponent as PersonIcon } from "../../images/header/person-icon.svg";
import { ReactComponent as Logo } from "../../images/header/ThePalmsLogo-sm-white.svg";
import { Link, useNavigate } from "react-router-dom";

function Header() {
  const navigate = useNavigate();

  function navigateHandler(route) {
    navigate(route);
	}
  return (
    <section className="header-container">
      <div className="common-container">
        <div className="header-content">
          <Link
            to='/'
            onClick={() => {
              window.scrollTo({
              left: 0,
              top: 0,
              behavior: 'smooth',
              });
            }}
          >
            {/* <img className="header-logo" src={thePalmsLogo} alt="The_Palms_Logo" /> */}
          <Logo className="header-logo"/>
          </Link>
          <div className="header-links">
            <div className="header-link" onClick={() => navigateHandler("/#aboutUs")}>About</div>
            <div className="header-link" onClick={() => navigateHandler("/#programs")}>Programs</div>
            <div className="header-link" onClick={() => navigateHandler("/#coaches")}>Coaches</div>
            <div className="header-link" onClick={() => navigateHandler("/#findClub")}>Rental Courts</div>
            <div className="header-link" onClick={() => navigateHandler("/#services")}>Services</div>
            <div className="header-link" onClick={() => navigateHandler("/#contactUs")}>Contact Us</div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            {/* <div className="header-divider"></div>
            <div className="header-icon">
              <SearchIcon/>
            </div>
            <div className="header-icon">
              <PersonIcon/>
            </div>
            <div className="header-signin">Sign in</div> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Header;
