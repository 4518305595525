import { React } from "react";
import "../../styles/hero/index.css";
// import heroImage from "../../images/hero/heroImage.png";
import heroImage from "../../images/hero/hero-image-new.png";
import { ReactComponent as RightArrowIcon } from "../../images/right-arrow.svg";
import { ReactComponent as LeftArrowIcon } from "../../images/left-arrow.svg";
import { Link } from "react-router-dom";

function Hero() {
  return (
    <section className="hero-container">
      {/* <div className="hero-arrow-right">
        <RightArrowIcon/>
      </div> */}
      <div className="common-container">
        <div className="hero-content">
          <img className="hero-image" src={heroImage} alt="Hero Image" />
          <div className="hero-text-container">
            <div className="hero-text-content">
              <div className="hero-title">
                PLAY
              </div>
              <div className="hero-description">
                  The Palms Racquet Club & Academy is establishing the leading racquet club and academy platform in Saudi Arabia.
              </div>
            </div>
            <Link
              to='/AboutUs'
              onClick={() => {
                window.scrollTo({
                left: 0,
                top: 0,
                behavior: 'smooth',
                });
              }}
            >
              <div className="hero-button">
                  <div className="hero-button-text">Learn More</div>
              </div>
            </Link>
          </div>
        </div>
      </div>
      {/* <div className="hero-arrow-left">
        <LeftArrowIcon/>
      </div> */}
    </section>
  );
}

export default Hero;
