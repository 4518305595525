import React, { useEffect, useState } from "react";
import "../../styles/programs/index.css";
import adultProgram from "../../images/programs/adult-program.png";
import juniorProgram from "../../images/programs/junior-program.png";
import functionalFitnessProgram from "../../images/programs/functional-fitness-program.png";

import { ReactComponent as RightArrowIcon } from "../../images/right-arrow.svg";
import { useLocation } from "react-router-dom";
import AdultPopup from "./AdultPopup";
import JuniorPopup from "./JuniorPopup";
import FunctionalFitnessPopup from "./functionalFitnessPopup";

function Programs() {
  const location = useLocation();

  const [activeProgram, setActiveProgram] = useState("adult");
  const [adultModalIsOpen, setAdultModalIsOpen] = useState(false);
  const [juniorModalIsOpen, setJuniorModalIsOpen] = useState(false);
  const [functionalFitnessModalIsOpen, setFunctionalFitnessModalIsOpen] = useState(false);

  const openAdultModal = () => {
    setAdultModalIsOpen(true);
  };
  const closeAdultModal = () => {
    setAdultModalIsOpen(false);
  };

  const openJuniorModal = () => {
    setJuniorModalIsOpen(true);
  };
  const closeJuniorModal = () => {
    setJuniorModalIsOpen(false);
  };
  
  const openFunctionalFitnessModal = () => {
    setFunctionalFitnessModalIsOpen(true);
  };
  const closeFunctionalFitnessModal = () => {
    setFunctionalFitnessModalIsOpen(false);
  };


  useEffect(() => {
    // Scroll to the section when the component mounts if the URL includes the anchor
    const element = document.getElementById("programs");
    if (element && location.hash.includes("#programs")) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    // const activeProgram = location.hash.split("#")[2];
    // if (activeProgram) {
    //   setActiveProgram(activeProgram);
    // }
  }, [location]);
  return (
    <section className="programs-container" id="programs">
      <div className="common-container">
        <div className="programs-header">
          <div className="programs-title">Our Programs</div>
          <div className="programs-description">
          We offer specialized programs for women and children, ensuring that  every member receives the attention and guidance needed to excel, from  beginners to advanced players.
          </div>
        </div>
        <div className="programs-content">
          <div className="programs-list">

            {/* Adult Program*/}
            <div className={`program-card ${activeProgram == "adult"? "active" : ""}`} onClick={()=> setActiveProgram("adult")}>
              <div className="program-info">
                <div className="program-icon">
                  <div className="program-number">01</div>
                </div>
                <div className="program-name">Adults</div>
              </div>
              <div className="program-arrow adults">
                <RightArrowIcon/>
              </div>
            </div>

            {/* Junior Program*/}
            <div className={`program-card ${activeProgram == "junior"? "active" : ""}`} onClick={()=> setActiveProgram("junior")}>
              <div className="program-info">
                <div className="program-icon junior-icon">
                  <div className="program-number">02</div>
                </div>
                <div className="program-name junior-name">Juniors</div>
              </div>
              <div className="program-arrow">
                <RightArrowIcon/>
              </div>
            </div>

            {/* Functional Fitness Program*/}
            <div className={`program-card ${activeProgram == "functionalFitness"? "active" : ""}`} onClick={()=> setActiveProgram("functionalFitness")}>
              <div className="program-info">
                <div className="program-icon junior-icon">
                  <div className="program-number">03</div>
                </div>
                <div className="program-name junior-name">Functional Fitness</div>
              </div>
              <div className="program-arrow">
                <RightArrowIcon/>
              </div>
            </div>
          </div>
          {
            activeProgram == "adult" &&
            <div className="program-details">
              <div className="details-title">Adult Program</div>
              <div className="details-content">
                <img className="details-image" src={adultProgram} alt="Adult Program" />
                <div className="details-text">
                  In the world around 50% of tennis players are women and 50% are men. The aim at The Palms Racket Club is to create a favourable tennis environment to start, play and improve your tennis game. Programs, Training, Pedagogy, Rackets, Balls are customized by level and gender. Adapted racket can be purchased on this app or on the tennis court. Tennis Shoes are mandatory.
                </div>
              </div>
              <div className="details-button" onClick={openAdultModal}>
                <div className="button-text">Check all Details</div>
                <RightArrowIcon/>
              </div>
            </div>
          }
          {
            activeProgram == "junior" &&
            <div className="program-details">
              <div className="details-title">Junior Program</div>
              <div className="details-content">
                <img className="details-image" src={juniorProgram} alt="Junior Program" />
                <div className="details-text">
                  The aim of the Junior Program at The Palms Racket Club is to create a favourable tennis environment to improve the game of your kid. Programs, Training, Pedagogy, Rackets, Balls and court size are customized according to the age of the children. Adapted racket can be purchased on this app. Tennis Shoes are mandatory.
                </div>
              </div>
              <div className="details-button" onClick={openJuniorModal}>
                <div className="button-text">Check all Details</div>
                <RightArrowIcon/>
              </div>
            </div>
          }
          {
            activeProgram == "functionalFitness" &&
            <div className="program-details">
              <div className="details-title">Functional Fitness</div>
              <div className="details-content">
                <img className="details-image" src={functionalFitnessProgram} alt="Functional Fitness Program" />
                <div className="details-text">
                The Functional Tennis program offers tailored fitness sessions designed to enhance your physical skills on the court. For adults (17+), a 1-hour weekly session is available for 80 SAR, targeting strength, stamina, and overall conditioning. Families can also participate together, with a weekend session priced at 80 SAR per parent and 40 SAR per child (10+). Tennis shoes are mandatory to ensure safety, and the sessions are specifically structured to benefit tennis players of all levels.
                </div>
              </div>
              <div className="details-button" onClick={openFunctionalFitnessModal}>
                <div className="button-text">Check all Details</div>
                <RightArrowIcon/>
              </div>
            </div>
          }
        </div>
      </div>
      <AdultPopup modalIsOpen={adultModalIsOpen} openModal={openAdultModal} closeModal={closeAdultModal}/>
      <JuniorPopup modalIsOpen={juniorModalIsOpen} openModal={openJuniorModal} closeModal={closeJuniorModal}/>
      <FunctionalFitnessPopup modalIsOpen={functionalFitnessModalIsOpen} openModal={openFunctionalFitnessModal} closeModal={closeFunctionalFitnessModal}/>
    </section>
  );
}

export default Programs;
